import Vue from 'vue';

class Util {
    readonly #events = new Vue();
    public emit(event: string, ...args: any[]) {
        this.#events.$emit.apply(this.#events, [event, ...args]);
    }

    public on(event: string | string[], callback: (...args: any) => void) {
        this.#events.$on(event, callback);
    }

    // 移除监听事件
    public remove(event: string | string[], callback: Function) {
        this.#events.$off(event, callback);
    }

    /**
     * 获取url的参数
     */
    /*public getUrlParams(name: string) {
        if (this.#events.$route.fullPath.includes('?')) {
            return this.#events.$route.query[name];
        } else {
            return this.#events.$route.params[name];
        }
    }*/

    public showToast(message: string) {}

    /** 数值精度 **/
    public numericalAccuracy(value: number) {
        const valueArr = value.toFixed(12).split('.');
        return Number(valueArr[0] + '.' + valueArr[1].substr(0, 2));
    }

    // 判断是否是微信浏览器
    public isWeiXin() {
        const agent: string = navigator.userAgent.toLowerCase();
        const search = agent.match(/MicroMessenger/i);
        if (search && search[0] === "micromessenger") return true;
        else return false;
    }

    // 判断是否为支付宝浏览器
    public isALiBrower() {
        const agent: string = navigator.userAgent;
        if (/AlipayClient/.test(agent)) return true;
        else return false;
    }
}

export default new Util();

/*
declare module "vue/types/vue" {
    interface Vue {
        $util: Util;
    }
}*/
