import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        testData: 'testData123456'
    },
    mutations: {},
    actions: {},
    modules: {}
});

export default store;
