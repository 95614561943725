import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/history/index.vue'),
  },
  {
    path: '/history/list',
    name: 'list',
    component: () => import('@/views/history/list.vue'),
  },
  {
    path: '/lead',
    name: 'lead',
    component: () => import('@/views/lead/index.vue'),
  },
  {
    path: '/verticalLead',
    name: 'verticalLead',
    component: () => import('@/views/verticalLead/index.vue'),
  },
  {
    path: '/verticalMessage',
    name: 'verticalMessage',
    component: () => import('@/views/verticalMessage/index.vue'),
  },
  {
    path: '/care',
    name: 'care',
    component: () => import('@/views/care/index.vue'),
  },
  {
    path: '/crosswise',
    name: 'crosswise',
    component: () => import('@/views/crosswise/index.vue'),
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/message/index.vue'),
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
