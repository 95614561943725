import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import Store from '@/store';
import Util from '@/util/util';
import Api from '@/api/api';
import * as echarts from 'echarts';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';

// declare module "vue/types/vue" {
//   interface Vue {
//     $echarts: any;
//   }
// }

Vue.prototype.$util = Util;
Vue.prototype.$api = Api;
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  // 状态管理
  store: Store,
  render: h => h(App)
}).$mount('#app');
