
import Vue from 'vue';
export default Vue.extend({
    name: 'headNav',
    data() {
        return {
            currentTime: {},
            province:'海南',
            weatcherData: {
                adcode: "",
                city: "三沙市",
                humidity: "",
                info: "",
                province: "",
                reportTime: "",
                temperature: 0,
                weather: "",
                windDirection: "",
                windPower: "",
            },

        }
    },
    mounted() {
        // 初始调用一次
        this.updateCurrentTime();
        this.getWeather(this.weatcherData.city);
        // 每秒刷新一次
        setInterval(() => {
            this.updateCurrentTime();
        }, 1000);
        setInterval(() => {
            this.getWeather(this.weatcherData.city);
        }, 3600000);
    },
    methods: {
        updateCurrentTime() {
            const date = new Date();
            const time = date.toLocaleTimeString();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) month = `0${month}`;
            if (day < 10) day = `0${day}`;
            const weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            this.currentTime = { week: weekArr[date.getDay()], date: `${year}-${month}-${day}`, time: time };
        },
        // 获取天气
        getWeather(city) {
            let that = this;
            //加载天气查询插件
            AMap.plugin("AMap.Weather", function () {
                //创建天气查询实例
                let weather = new AMap.Weather();
                //执行实时天气信息查询
                weather.getLive(city, function (err, data) {
                    // console.log(data);
                    if (data.info == "OK") {
                        that.weatcherData = data;
                    }
                });
            });
        },
    },
});
