class Api {
    // public async getUserInfo(params: API.GET.userParams) {
    //     const {get} = await import('@/api/home/getUserInfo');
    //     return get(params);
    // }

    public async postList(params: API.POST.listParams) {
        const {post} = await import('@/api/home/list');
        return post(params);
    }
    public async postSort(params: API.POST.sortParams) {
        const {post} = await import('@/api/sort/postSort');
        return post(params);
    }
    public async postYear(params: API.POST.yearParams) {
        const {post} = await import('@/api/year/postYear');
        return post(params);
    }
    public async postMessage(params: API.POST.messageParams) {
        const {post} = await import('@/api/message/postMessage');
        return post(params);
    }
    public async postSubmit(params: API.POST.submitParams) {
        const {post} = await import('@/api/message/postSubmit');
        return post(params);
    }

}

/*const API = {
    getUserInfo: async (params: API.GET.userParams) => {
        // const {get} = await import('#/home/getUserInfo');
        const {get} = await import('../api/home/getUserInfo');
        return get(params);
    },
}*/

// 返回API实例
export default new Api;

/** 声明模块 - Vue接口增加 **/
declare module "vue/types/vue" {
    interface Vue {
        $api: Api;
    }
}

//定义module 需要ts代码提示必须执行下方代码
/*declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $API: API; // 挂载类型
    }
}*/
